.login{
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin: 0;
}


.login .body {
  /* height: 100vh; */
  width: 100vw;
  margin: 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: #f3f2f2;
}



@media (max-width: 768px) {
  /* Styles for screens up to 768px width */
  .login .body .session{
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .login .body .session{
    padding: 20px;
  }
}

@media (max-width: 320px) {
  .login .body .session input,button{
    font-size: small;
    margin-left:0; 
  }

  .login .body .session .col-md-auto form,input,.floating-label {
    width: 50%;  
  }
}




.login h4 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  opacity: .85;
}

.login label {
  font-size: 12.5px;
  color: #000;
  opacity: .8;
  font-weight: 400;
}

.login form {
  padding: 20px 15px;
  background: #fefefe;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-bottom: 20px; */
  width: 300px;
  max-height: 80vh;
  /* overflow-y: auto;  
  overflow-x: hidden;  */
  /* padding-right: 4cm; */





  h4 {
    margin-bottom: 20px;
    color: rgba(#000, .5);

    span {
      color: rgba(#000, 1);
      font-weight: 700;
    }
  }

  p {
    line-height: 155%;
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    opacity: .65;
    font-weight: 400;
    max-width: 200px;
    margin-bottom: 40px;
  }
}

.login a.discrete {
  color: rgba(#000, .4);
  font-size: 14px;
  border-bottom: solid 1px rgba(#000, .0);
  padding-bottom: 4px;
  margin-left: auto;
  font-weight: 300;
  transition: all .3s ease;
  margin-top: 40px;

  &:hover {
    border-bottom: solid 1px rgba(#000, .2);
  }
}

.login button {
  -webkit-appearance: none;
  width: auto;
  min-width: 100px;
  border-radius: 24px;
  text-align: center;
  padding: 7px 20px;
  margin-top: 5px;
  background-color: saturate(#a4c639, 30%);
  color: #fff;
  font-size: 14px;
  margin-left: auto;
  font-weight: 500;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, .13);
  border: none;
  transition: all .3s ease;
  outline: 0;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 2px 6px -1px rgba(#a4c639, .65);

    &:active {
      transform: scale(.99);
    }
  }
}

.login input {
  font-size: 16px;
  padding: 20px 0px;
  height: 56px;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, .1);
  background: #fff;
  width: 280px;
  box-sizing: border-box;
  transition: all .3s linear;
  color: #000;
  font-weight: 400;
  -webkit-appearance: none;



  &:focus {
    border-bottom: solid 1px #ff8b00;
    outline: 0;
    box-shadow: 0 2px 6px -8px rgba(#ff8b00, .45);
  }
}

.login .floating-label {
  position: relative;
  margin-bottom: 10px;
  width: 100%;

  label {
    position: absolute;
    top: calc(50% - 7px);
    left: 0;
    opacity: 0;
    transition: all .3s ease;
    padding-left: 44px;
  }

  .login input {
    width: calc(100% - 44px);
    margin-left: auto;
    display: flex;
  }

  .login .icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    width: 44px;
    display: flex;

    svg {
      height: 30px;
      width: 30px;
      margin: auto;
      opacity: .15;
      transition: all .3s ease;

      path {
        transition: all .3s ease;
      }
    }
  }

  .login input:not(:placeholder-shown) {
    padding: 28px 0px 12px 0px;
  }

  .login input:not(:placeholder-shown)+label {
    transform: translateY(-10px);
    opacity: .7;
  }

  .login input:valid:not(:placeholder-shown)+label+.icon {
    svg {
      opacity: 1;

      path {
        fill: #a4c639;
      }
    }
  }

  .login input:not(:valid):not(:focus)+label+.icon {
    animation-name: shake-shake;
    animation-duration: .3s;
  }
}

/* $displacement: 3px; */

@keyframes shake-shake {
  0% {
    transform: translateX(3px);
  }

  20% {
    transform: translateX(3px);
  }

  40% {
    transform: translateX(3px);
  }

  60% {
    transform: translateX(3px);
  }

  80% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(0px);
  }
}


.login .session {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  margin: auto auto auto auto;
  margin-top: 10rem;
  margin-bottom:  6rem;

  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, .12);
  max-height: 80vh;  /* Set a maximum height for the container */
}


.login .left {
  width: 220px;
  height: auto;
  min-height: 100%;
  position: relative;
  background-image: url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  svg {
    height: 40px;
    width: auto;
    margin: 20px;
  }
}


/****Slider ****/
.Modern-Slider .NextArrow {
  opacity: 0.7;
}

.Modern-Slider .PrevArrow {
  opacity: 0.7; 
}


#top input[type="range"]::-webkit-slider-thumb {
  background: #ff8b00;  
}
.controlled-input-password{
  border: none;
}
.controlled-input-component{
  border:1px solid #ced4da;
}


.dropdown-content {
  display: none;
  position: absolute;
  text-align: start;
  top: 60px;
  margin: auto;
  background-color: transparent;
  min-width: 100px;
  z-index: 1;
  padding: 16px;
  color: #f3f2f2;
}

.dropdown-content a{
  color: #a4c639;
}


/* Show the dropdown when the parent is hovered */
.nav-item:hover .dropdown-content {
  display: block;
}

.loan-item{
  background-color: #f7f7f7;
	padding: 30px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.curved-container {
  border-top-left-radius: 50% 20px;
  border-top-right-radius: 50% 20px;
  overflow: hidden;
  /* background-color: #f8f9fa; Add your desired background color here   */
  padding: 20px;
}


/**************** Accordion ***************/
/* Customize the accordion header */
.terms{
  align-items: center;
  text-align: center;
 margin: auto;
 padding: 10px;
 height: 110vh;
}

.terms ul li{
  text-align: start;
  line-height: 1em;
  font-size: 14px;
  color: rgb(83, 83, 83);
  padding: 5px;
}


.accordion-button {
  background-color: #fff;
  color: #00bfba;
  border-radius: 5px;
  padding: 10px;
  font-size: 17px;
  width: 80%;
  border: .1px solid #ddd;
  text-align: start;

}

/* Hover effect for accordion header */
.accordion-button:hover {
  background-color: #ffffffec;
  color: rgba(5, 126, 122, 0.774);
}

/* Active (open) state for accordion header */
.accordion-button[aria-expanded="true"] {
  /* background-color: #d8d811; */
  border: .1px solid #ddd;

}

/* Style the accordion body */
.accordion-body {
  align-items: center;
  text-align: center;
  margin: auto;
  padding: auto;
  background-color: #f8f9fa;
  padding: 15px;
  border: .1px solid #ddd;
  border-radius: 0 0 1px 1px;
  width: 80%;
}

/* Add some shadow to the accordion body for a card-like effect */
.accordion-item {
  width: 100%;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  margin-bottom: 10px;
}

/* Optional: Remove default box-shadow on active state (open accordion) */
.accordion-item.active {
  box-shadow: none;
}




.caret_down{
  margin-right: .5cm;
  /* font-size: 25px; */
  text-align: end;
}

